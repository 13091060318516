/* eslint-disable */

import { createApp } from "vue";
import App from "./App.vue";
import { router } from "./router/index";

createApp(App).use(router).mount("#app");


import StringExtension from "./extensions/StringExtensions";
