/* eslint-disable */

const HOST = 'https://api.afterdoc.net'
const URL_AUTH = HOST + '/v2/auth'

// - 휴대폰 인증번호 요청 v2/auth/phone/mw/request
// - 휴대폰 인증번호 확인 v2/auth/phone/mw/verify
// - 서비스 중단 v2/auth/user/mw/suspend

const AUTH_REQUEST = URL_AUTH + '/phone/mw/request'
const AUTH_VERIFY  = URL_AUTH + '/phone/mw/verify'
const AUTH_SUSPEND = URL_AUTH + '/user/mw/suspend'

export default {
    AUTH_REQUEST: AUTH_REQUEST,
    AUTH_VERIFY: AUTH_VERIFY,
    AUTH_SUSPEND: AUTH_SUSPEND
}