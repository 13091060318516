<template>
  <div>
    <div class="wrapper">
      <div class="main_title">
        <span style="color: #0075e1;">계정 삭제</span>가 완료되었습니다.
      </div>
      <div class="body_text">모든 정보가 정상적으로 삭제되었습니다.</div>
      <div class="body_text">
        남겨주신 의견을 바탕으로 더 나은 서비스를 제공하고자 노력하겠습니다.
        그동안 애프터닥을 이용해 주셔서 감사합니다.
      </div>
    </div>
  </div>
</template>

<script></script>

<style scoped>
.wrapper {
  text-align: center;
  gap: 16px;
}
.wrapper .main_title {
  margin-top: 40px;
}
</style>