<template>
  <div style="height: 100%">
    <div class="wrapper warp_page01">
      <div v-if="isShowIndicator" class="loading_bg"><span class="loader"></span></div>
      <div class="step">
        <span class="now">1</span><span class="total">/2</span>
      </div>
      <div class="main_title">애프터닥 계정 삭제</div>
      
      <div class="sub_title">본인인증</div>
      <div class="body_text">계정삭제를 위해 본인인증이 필요합니다.</div>
      <div class="verification">
        <div class="verification_box phone_num_wrapper">
          <p class="title_text">휴대폰번호</p>

          <div :class="isPhoneVerifyState">
            <div class="box box1" >
              <PhoneInput ref="phoneVue" @sendPhoneNumber="sendPhoneNumber" :isPhoneInputReadOnly="isPhoneInputReadOnly"/>
                <div v-if="phoneInputState == 'errorVerify'" class="error_box"><img src="@/assets/img/exclamationmark_circle.png" class="exclamationmark_circle"/>올바르지 않은 번호입니다.</div>
            </div>
            <a v-if="phone.length >= 12" @click="postRequestPhone" class="box box2 send_btn on">{{ isPhoneVerifyBtnState }}</a>
            <a v-if="phone.length < 12" class="box box2 send_btn">{{ isPhoneVerifyBtnState }}</a>
          </div>
        </div>

        <div class="verification_box authentication_num_wrapper">
          <p class="title_text">인증번호</p>
          <input
            type="text"
            :maxlength="6"
            placeholder="인증번호 6자리"
            @input="certificationInput"
            v-model="certificationNumber"
            :class="isCertificationState"
            :readonly="isCertificationReadOnly" />
        <div class="error_box" v-if="certificationInputState == 'errorVerify'">
          <img class="exclamationmark_circle" src="@/assets/img/exclamationmark_circle.png" />인증번호가 올바르지 않습니다.</div>
        </div>
      </div>

      <div class="check">
        <a v-if="phone" @click="action_Reset" class="cancle_btn">취소</a>
        <a @click="action_Confirm" class="check_btn">확인</a>
      </div>
    </div>
  </div>  
  
  <!-- 인증번호 전송 -->
    <div v-if="isShowToast" class="toast_text show">
      인증번호가 발송되었습니다.
    </div>
</template>

<script>
import PhoneInput from "./PhoneInput.vue";
import API from "@/api/API";

export default {
  components: { PhoneInput },
  data() {
    return {
      phone: '',
      certificationNumber: '',

      phoneInputState: 'beforeVerify',
      certificationInputState: 'beforeVerify',

      phoneID: undefined,
      canConfirmClick: true,
      isShowToast: false,
      isShowIndicator: false,
      isPhoneInputReadOnly: false,
    };
  },
  watch: {
    phone: {
      handler: function(newValue, oldValue) {
        if(this.phoneInputState == 'errorVerify' && newValue != oldValue) { 
          this.phoneInputState = 'beforeVerify' 
        }
      }
    },
    certificationNumber: {
      handler: function(newValue, oldValue) {
        if(this.certificationInputState == 'errorVerify' && newValue != oldValue) {
          this.certificationInputState = 'postVerify'
        }
      }
    }
  },
  computed: {
    isPhoneVerifyState: function() {
      if(this.phoneInputState == 'beforeVerify') {return 'phone_num_box'}
      if(this.phoneInputState == 'postVerify') {return 'phone_num_box fixed'}

      return 'phone_num_box error'
    },
    isPhoneVerifyBtnState: function() {
      if(this.phoneInputState == "postVerify") { return "재발송" }

      return '인증'
    },
    isCertificationState: function() {
      if(this.certificationInputState == 'beforeVerify') { return 'authentication_num' }
      if(this.certificationInputState == 'postVerify') { return 'authentication_num on'}
      if(this.phoneInputState == 'postVerify') { return 'authentication_num error'}

      return 'authentication_num error'
    },
    isCertificationReadOnly: function() {
      if(this.certificationInputState != 'beforeVerify') { return false }

      return true
    }
  },
  methods: {
    certificationInput(e) {
      let newValue = e.target.value
      const regex = /[가-힣ㄱ-ㅎㅏ-ㅣa-zA-Z]/;

      if(newValue.match(regex)) {
        this.certificationNumber = '';
        this.$forceUpdate();
      }
    },
    sendPhoneNumber(h) {
      this.phone = h
    },
    action_Reset() {
      this.phone = ''
      this.certificationNumber = ''
      this.isPhoneInputReadOnly = false
      this.phoneInputState = 'beforeVerify'
      this.certificationInputState = 'beforeVerify'

      const resetInstance = this.$refs.phoneVue;
      resetInstance.setResetFunction();
    },
    action_Confirm() {
        if(this.phoneID == undefined) { this.phoneInputState = 'errorVerify' } 
        else { this.postRequestCode() }
    },
    // 휴대폰 인증번호 요청
    async postRequestPhone() {
      if(this.canConfirmClick) {
      
      this.canConfirmClick = false;
      
      let phone = this.phone.replace(/[^0-9]/g, "");
      let model = { phone: phone }
      
      try {
        let result = await API.Auth.request(model)
        if(result.data.code == 0) {
          this.isShowToast = true;
          this.isPhoneInputReadOnly = true
          this.phoneInputState = 'postVerify'
          this.certificationInputState = 'postVerify'
          this.phoneID = result.data.data.phoneID
        } 
        if(result.data.code != 0) { this.phoneInputState = 'errorVerify' }
      } catch(err) { console.log(err) }

      setTimeout(() => {
        this.canConfirmClick = true;
        this.isShowToast = false
      }, 1000);
    }},
    // 휴대폰 인증번호 
    async postRequestCode() {
      this.isShowIndicator = true
      let model =  {
        phoneID: this.phoneID,
        code: this.certificationNumber
      }
      try {
        let result = await API.Auth.verify(model)

        if(result.data.code == 0) { 
          this.$emit('setConfirm', result.data.data)
        }
        if(result.data.code != 0) {
          if(result.data.code == 1514) {
            alert('인증 가능한 시간이 초과되었습니다. 인증번호를 다시 요청해주세요.')
            this.certificationInputState = 'beforeVerify'
            this.certificationNumber = ''
          } 
          else { this.certificationInputState = "errorVerify" }
        }
        this.isShowIndicator = false
      } catch(err) { console.log(err)}
    }
  }
};
</script>
