<template>
  <div>
    <input type="text" class="phone_num" placeholder="예) 010-1234-1234" ref="tf_phone" :value="value" @input="handleInput" :maxlength="13" :readonly="isPhoneInputReadOnly" />
  </div>
</template>


<script>
export default {
  props: { isPhoneInputReadOnly: Boolean },
  data() {
    return {
      value: '',
      pastedValue: -1
    };
  },

  methods: {
    setResetFunction() {
        this.$nextTick(() => {
          this.value = '';
        });
    },
    handleInput(e) {
      let newValue = e.target.value.phoneHyphenString;
      
      const regex = /[가-힣ㄱ-ㅎㅏ-ㅣa-zA-Z]/;
      // eslint-disable-next-line no-useless-escape
      const specialCharacterRegex = /[!@₩#\$%^&*()_+={}[\]:;"'<>,.?\/\\|`~]/;
      
      if (e.target.value.match(regex) || e.target.value.match(specialCharacterRegex)) {
        this.value = '';
        this.$forceUpdate()
      } else {
        let origin = newValue.replace(/[^0-9]/g, "");
        if (origin.length < 12) {
          this.value = newValue
          this.$emit('sendPhoneNumber', this.value)
        }
      }
    }
  }
};
</script>


