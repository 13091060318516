<template>
  <div>
    <!-- 계정삭제 설문 -->
    <div class="wrapper wrap_page02">
    <div class="step">
        <span class="now">2</span><span class="total">/2</span>
      </div>
      <div class="main_title">애프터닥 계정 삭제</div>
      <div class="sub_title">계정을 삭제하려는 이유는 무엇인가요?</div>
      <div class="body_text">
        삭제 사유를 선택해주시면 고객님의 의견을 참고하여 더 좋은 서비스를
        제공할 수 있도록 하겠습니다.
      </div>
      <ul class="survey"> 
        <li class="list list_01">
          <input type="checkbox" id="list1" value="몸상태가 건강해졌어요." v-model="checkboxValue" /><label for="list1"
            >몸상태가 건강해졌어요.</label
          >
        </li>
        <li class="list list_02">
          <input type="checkbox" id="list2" value="이젠 이 병원을 이용하지 않아요." v-model="checkboxValue" ><label for="list2"
            >이젠 이 병원을 이용하지 않아요.</label
          >
        </li>
        <li class="list list_03">
          <input type="checkbox" id="list3" value="서비스를 잘 안쓰게 돼요." v-model="checkboxValue" /><label for="list3"
            >서비스를 잘 안쓰게 돼요.</label
          >
        </li>
        <li class="list list_04">
          <input type="checkbox" id="list4" value="기타" v-model="checkboxValue" /><label for="list4">기타</label>
          <textarea v-if="isCheckAgree" name="etc" id="list4" class="text_field" v-model="textFiledValue"></textarea>
        </li>
      </ul>
      <div class="body_text">
        계정 삭제시 애프터닥에 등록한 내 정보와 채팅기록이 모두 사라지며,
        <span style="color: #ec5500;">삭제된 정보는 복구되지 않습니다.</span>
      </div>
      <div class="consent">
        <li class="list">
          <input type="checkbox" id="list5" v-model="isAgree"/><label for="list5">위 내용을 모두 확인하였으며, 이에 동의합니다.</label>
        </li>
      </div>
      <div class="check">
        <a @click="action_Close" class="cancle_btn">취소</a>
        <a v-if="!isAgreeCheck" class="delete_btn">계정삭제</a>
        <a v-if="isAgreeCheck" @click="action_Click" class="delete_btn on">계정삭제</a>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  data() {
    return {
      isAgree: false,
      isAgreeCheck: false,
      checkboxValue: [],
      errormessage: '',
      textFiledValue: '',
    }
  },
  watch: {
    isAgree: {
      handler: function(newValue, oldValue) {
        if(newValue == undefined) {return}
        if(newValue != oldValue) {
           if (this.checkboxArray.length != 0 && newValue == true) { this.isAgreeCheck = true } 
           else { this.isAgreeCheck = false }
        }
      }
    },
    checkboxArray : {
      immediate: true,
      handler: function(newValue, oldValue) {
        if(newValue == undefined) { return }
        if(newValue != oldValue) { this.getCheckBoxArray() }
        if(newValue.length != 0 && this.isAgree == true) { this.isAgreeCheck = true } 
        else { this.isAgreeCheck = false}
      }
    },
    
  },
  computed:{
    isCheckAgree: function() {
      const myArray = this.checkboxValue
      const valueToCheck = '기타'
      if (myArray.includes(valueToCheck)) { return true } 
      else { return false }
    }, 
    checkboxArray: function() {
      let parse = JSON.parse(JSON.stringify(this.checkboxValue))
      if(parse.includes('기타')) {
        const index = parse.indexOf('기타');
        parse[index] = this.textFiledValue
      }
      return parse
    }
  },
  methods: {
    action_Click() {
      this.$emit('setDeleteAccount');
    },
    action_Close() {
      this.$emit('setCloseBtn')
    },
    getCheckBoxArray() {
      this.$emit('getCheckBoxArray', this.checkboxArray)
    }
  },
  
};
</script>
