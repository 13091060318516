import { createWebHistory, createRouter } from "vue-router";
import MainPage from "../../src/page/MainPage/MainPage.vue";
import CompletePage from '../../src/page/complete/CompletePage.vue';

const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: "/",
      name: "MainPage",
      component: MainPage
    },
    {
      path: '/complete',
      name: 'complete',
      component: CompletePage
    },
    {
      path: "/:catchAll(.*)",
      redirect: '/', 
      component: MainPage
  }
  ]
});

export { router }