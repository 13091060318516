import axios from 'axios';
import URL from './URL';

let request = async function(model) {
    return axios({
        method: 'POST',
        url: URL.AUTH_REQUEST,
        data: model
    })
}

let verify = async function(model) {
    return axios({
        method: 'POST',
        url: URL.AUTH_VERIFY,
        data: model
    })
}

let suspend = async function(model) {
    return axios({
        method: 'POST',
        url: URL.AUTH_SUSPEND,
        data: model
    })
}

export default {
    request: request,
    verify: verify,
    suspend: suspend
}