Object.defineProperty(String.prototype, "phoneHyphenString", {
  get: function() {
    let result = this;
    var str = result.replace(/[^0-9]/g, "");

    if (str.length < 4) {
      result = str;
    } else if (str.length < 7) {
      result = str.replace(/(\d{3})(\d{1,4})/, "$1-$2");
    } else if (str.length < 11) {
      result = str.replace(/(\d{3})(\d{3})(\d{1,4})/, "$1-$2-$3");
    } else {
      result = str.replace(/(\d{3})(\d{4})(\d{1,4})/, "$1-$2-$3");
    }
    return result;
  }
});
