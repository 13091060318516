<template>
    <div>
        <AuthenticatePage v-if="pageState.auth" @setConfirm="setConfirm" @accountInfo="setAccountInfo"/>
        <SurveyPage v-if="pageState.survey" @setCloseBtn="setCloseBtn" @getCheckBoxArray="getCheckBoxArray" @setDeleteAccount="setDeleteAccount"/>
    </div>
</template>

<script>
import API from '../../api/API'
import AuthenticatePage from '../authenticatePage/AuthenticatePage.vue';
import SurveyPage from '../surveyPage/SurveyPage.vue';

export default {
    components : { AuthenticatePage, SurveyPage },
    data() {
        return {
            pageState: {
                auth: true,
                survey: false
            },
            suspendInfo: {
                userID: undefined,
                phoneVerifyID: undefined,
                closeReason: undefined
            }
        }
    },
    watch: {

    },
    computed: {

    },
    methods: {
        // 계정삭제 사유 
        getCheckBoxArray(h) {
            this.suspendInfo.closeReason = h
        },
        // first page 확인 버튼
        setConfirm(h) {
            this.suspendInfo.userID = h.userID
            this.suspendInfo.phoneVerifyID = h._id

            this.pageState.auth = !this.pageState.auth;
            this.pageState.survey = !this.pageState.survey
        },
        // second page 취소 버튼
        setCloseBtn() {
            this.pageState.auth = true
            this.pageState.survey = false
        },
        setAccountInfo() {

        },
        // 계정삭제 버튼 클릭
        async setDeleteAccount() {
            let accountDeleteReason = JSON.parse(JSON.stringify(this.suspendInfo.closeReason))
            let model = {
                userID: this.suspendInfo.userID,
                phoneVerifyID: this.suspendInfo.phoneVerifyID,
                closeReason: accountDeleteReason
            }
            try {
                let result = await API.Auth.suspend(model);
                if(result.data.code == 0) {
                    // post clear
                    this.$router.replace({ path: "/complete" });
                }
            }
            catch(err) {console.log(err)}

        }
    }
}
</script>

<style>
</style>